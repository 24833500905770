
const XHRFactory = {
	config: {
		withCredentials: false,
		customHeaders: [],
	},

	createXMLHttpRequest: function () {
		let xhr = new XMLHttpRequest();

		if (this.config.customHeaders &&
			Array.isArray(this.config.customHeaders) &&
			this.config.customHeaders.length > 0) {
			let baseOpen = xhr.open;
			let customHeaders = this.config.customHeaders;
			xhr.open = function () {
				baseOpen.apply(this, [].slice.call(arguments));
				customHeaders.forEach(function (customHeader) {
					if (!!customHeader.header && !!customHeader.value) {
						xhr.setRequestHeader(customHeader.header, customHeader.value);
					}
				});
			};
		}

		return xhr;
	},

	getCustomHeadersForFetch() {
		const headers = {};
		this.config.customHeaders.forEach((customHeader) => {
			headers[customHeader.header] = customHeader.value;
		})
		return headers;
	}
};

export {XHRFactory};